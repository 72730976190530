document.addEventListener('click', function (event) {
  const target = event.target;

  if (
    !target.closest('[data-accordion-toggle]') ||
    !target.closest('[data-accordion-section]')
  ) {
    return;
  }

  const accordionSection = target.closest('[data-accordion-section]');
  const scrollPositionFix = fixScrollPosition(accordionSection);

  Array.from(accordionSection.parentNode.children).forEach((sibling) => {
    sibling.setAttribute('aria-expanded', 'false');
  });

  accordionSection.setAttribute('aria-expanded', 'true');

  if (scrollPositionFix) {
    window.scrollTo(0, scrollPositionFix);
  }
});

function fixScrollPosition(accordionSection) {
  const isCurrentSection =
    accordionSection.getAttribute('aria-expanded') === 'true';

  // No fixes needed
  if (isCurrentSection) {
    return false;
  }

  const accordion = accordionSection.parentNode;
  const prevCurrentSection = accordion.querySelector(
    `:scope > [data-accordion-section][aria-expanded="true"]`
  );
  const targetIndex = [...accordionSection.parentNode.children].indexOf(
    accordionSection
  );
  const prevCurrentSectionIndex = prevCurrentSection
    ? [...prevCurrentSection.parentNode.children].indexOf(prevCurrentSection)
    : false;
  const prevCurrentAccordionContentHeight =
    prevCurrentSection && prevCurrentSectionIndex < targetIndex
      ? prevCurrentSection.querySelector('[data-accordion-content]')
          ?.scrollHeight
      : 0;
  const accordionSectionActualOffset =
    accordionSection.getBoundingClientRect().top +
    window.scrollY -
    prevCurrentAccordionContentHeight;
  const viewTop = window.scrollY;
  const viewBottom = viewTop + window.innerHeight;
  const actualOffsetIsVisible =
    viewTop < accordionSectionActualOffset &&
    viewBottom > accordionSectionActualOffset;

  if (actualOffsetIsVisible) {
    return false;
  }

  return accordionSectionActualOffset;
}
