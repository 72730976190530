import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const lightbox = new PhotoSwipeLightbox({
  gallery: '.js-popup-single',
  pswpModule: PhotoSwipe,
  showHideAnimationType: 'none',
  bgOpacity: 1,
}).init();
