export const ScrollLocker = {
  className: "scroll-lock",
  enable: function () {
    document.body.classList.remove(this.className);
  },
  disable: function () {
    document.body.classList.add(this.className);
  },
  toggle: function (state = null) {
    if (state === null) {
      return;
    }

    state ? this.enable() : this.disable();
  },
};
