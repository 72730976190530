document.addEventListener('mousemove', (event) => {
  const target = event.target;

  if (!target.classList.contains('js-image-zoom')) {
    return;
  }

  const x = (event.offsetX / target.offsetWidth) * 100;
  const y = (event.offsetY / target.offsetHeight) * 100;

  target.style.backgroundPosition = `${x}% ${y}%`;
});
