import { ScrollLocker } from '../helpers/scroll-locker';

const breakpoint = window.matchMedia('(min-width: 1024px)');

// Menu Toggle
document.addEventListener('click', (event) => {
  if (!event.target.classList.contains('js-menu-toggle')) {
    return;
  }

  toggleMenu();
});

// Fix scroll lock on breakpoint change
breakpoint.addEventListener('change', resetMenuOnBreakpoint);

// Functionality for 2nd level mobile menu
document.addEventListener('click', (event) => {
  if (
    breakpoint.matches ||
    !event.target.classList.contains('js-nav-child-toggle')
  ) {
    return;
  }

  const parent = event.target.closest('li.has-children');
  parent.classList.toggle('is-expanded');
});

function toggleMenu() {
  document.body.classList.toggle('menu-opened');
  ScrollLocker.toggle(!document.body.classList.contains('menu-opened'));
}

function resetMenuOnBreakpoint() {
  if (!document.body.classList.contains('menu-opened')) {
    return;
  }

  ScrollLocker.toggle(breakpoint.matches);
}
