document.addEventListener("click", function (event) {
  const target = event.target;

  if (!target.classList.contains("js-tab-trigger")) {
    return;
  }

  const wrapper = target.closest(".js-tabs");
  const index = [
    ...target.closest(".js-tabs").querySelectorAll(".js-tab-trigger"),
  ].indexOf(target);

  wrapper
    .querySelectorAll(".js-tab, .js-tab-trigger")
    .forEach((el) => el.classList.remove("is-current"));
  target.classList.add("is-current");
  wrapper.querySelectorAll(".js-tab").item(index).classList.add("is-current");
});
