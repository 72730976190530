const breakpoint = window.matchMedia('(min-width: 1024px)');

updateParallax();
window.addEventListener('load', updateParallax);
window.addEventListener('resize', updateParallax);
window.addEventListener('scroll', updateParallax);
window.addEventListener('orientationchange', updateParallax);

function updateParallax() {
  const imageContainers = document.querySelectorAll('.js-image-parallax');

  imageContainers.forEach((container) => {
    const image = container.querySelector('img');

    if (breakpoint.matches) {
      const imageHeight = image.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollBottom = window.scrollY + windowHeight;
      const elemTop = image.getBoundingClientRect().top + window.scrollY;
      const elementOffset = scrollBottom - elemTop;
      const multiplier = Math.min(
        Math.max(0, elementOffset / (windowHeight + imageHeight)),
        1
      );
      const transform = imageHeight * multiplier;
      const transformValue = `0, calc(${transform.toFixed(2)}px - 50%), 0`;

      image.style.transform = `scale(1.5) translate3d(${transformValue})`;
    } else {
      // Disable on mobile
      image.style.transform = '';
    }
  });
}
