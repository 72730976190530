document.addEventListener("click", (event) => {
  if (!event.target.classList.contains("js-search-toggle")) {
    return;
  }

  document.body.classList.toggle("search-opened");

  // bring focus to #search-input if search is opened
  if (document.body.classList.contains("search-opened")) {
    document.querySelector("#search-input").focus();
  }
});
